import React from 'react'
import Page from '../components/Page/Page'

const Datenschutz = () => {

  return(
    <Page
      title={'Datenschutz'}
      renderCallback={() => {
        return(
          <div style={{lineHeight: '1.5'}}>
            <p>Personenbezogene Daten (nachfolgend zumeist nur &bdquo;Daten&ldquo; genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsf&auml;higen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
            <p>Gem&auml;&szlig; Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur &bdquo;DSGVO&ldquo; genannt), gilt als &bdquo;Verarbeitung&ldquo; jeder mit oder ohne Hilfe automatisierter Verfahren ausgef&uuml;hrter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch &Uuml;bermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verkn&uuml;pfung, die Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung.</p>
            <p>Mit der nachfolgenden Datenschutzerkl&auml;rung informieren wir Sie insbesondere &uuml;ber Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend &uuml;ber die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualit&auml;t eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
            <p>Unsere Datenschutzerkl&auml;rung ist wie folgt gegliedert:</p>
            <p>I. Informationen &uuml;ber uns als Verantwortliche<br />II. Rechte der Nutzer und Betroffenen<br />III. Informationen zur Datenverarbeitung</p>
            <h3>I. Informationen &uuml;ber uns als Verantwortliche</h3>
            <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
            <p>Walter Neubauer<br />Lothringerstraße 11<br /> A-1010 Wien<br />Österreich</p>
            <p>Telefon: +43 1 718 10 58<br />Telefax: +43 1 908 14 08<br />E-Mail: walter@neubauer-geigenbau.com</p>
            <h3>II. Rechte der Nutzer und Betroffenen</h3>
            <p>Mit Blick auf die nachfolgend noch n&auml;her beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
            <ul>
              <li>auf Best&auml;tigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft &uuml;ber die verarbeiteten Daten, auf weitere Informationen &uuml;ber die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
              <li>auf Berichtigung oder Vervollst&auml;ndigung unrichtiger bzw. unvollst&auml;ndiger Daten (vgl. auch Art. 16 DSGVO);</li>
              <li>auf unverz&uuml;gliche L&ouml;schung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gem&auml;&szlig; Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschr&auml;nkung der Verarbeitung nach Ma&szlig;gabe von Art. 18 DSGVO;</li>
              <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf &Uuml;bermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
              <li>auf Beschwerde gegen&uuml;ber der Aufsichtsbeh&ouml;rde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Versto&szlig; gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
            </ul>
            <p>Dar&uuml;ber hinaus ist der Anbieter dazu verpflichtet, alle Empf&auml;nger, denen gegen&uuml;ber Daten durch den Anbieter offengelegt worden sind, &uuml;ber jedwede Berichtigung oder L&ouml;schung von Daten oder die Einschr&auml;nkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unm&ouml;glich oder mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft &uuml;ber diese Empf&auml;nger.</p>
            <p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die k&uuml;nftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Ma&szlig;gabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong></p>
            <h3>III. Informationen zur Datenverarbeitung</h3>
            <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gel&ouml;scht oder gesperrt, sobald der Zweck der Speicherung entf&auml;llt, der L&ouml;schung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
            <h4>Cookies</h4>
            <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
            <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endger&auml;t ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;</p>
            <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion erm&ouml;glicht.</p>
            <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
            <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalit&auml;t unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
            <p>Mit Schlie&szlig;en Ihres Internet-Browsers werden diese Session-Cookies gel&ouml;scht.</p>
            <h5>b) Drittanbieter-Cookies</h5>
            <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalit&auml;ten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
            <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
            <h5>c) Beseitigungsm&ouml;glichkeit</h5>
            <p>Sie k&ouml;nnen die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschr&auml;nken. Ebenfalls k&ouml;nnen Sie bereits gespeicherte Cookies jederzeit l&ouml;schen. Die hierf&uuml;r erforderlichen Schritte und Ma&szlig;nahmen h&auml;ngen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht &uuml;ber die Einstellungen des Browsers unterbunden werden. Stattdessen m&uuml;ssen Sie insoweit die Einstellung Ihres Flash-Players &auml;ndern. Auch die hierf&uuml;r erforderlichen Schritte und Ma&szlig;nahmen h&auml;ngen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
            <p>Sollten Sie die Installation der Cookies verhindern oder einschr&auml;nken, kann dies allerdings dazu f&uuml;hren, dass nicht s&auml;mtliche Funktionen unseres Internetauftritts vollumf&auml;nglich nutzbar sind.</p>
            <h4>Google Analytics</h4>
            <p>In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur &bdquo;Google&ldquo; genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild (&bdquo;EU-US Privacy Shield&ldquo;)</p>
            <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder H&auml;ufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion k&uuml;rzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.</p>
            <p>Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung &uuml;ber den Besuch unseres Internetauftritts sowie &uuml;ber die dortigen Nutzungsaktivit&auml;ten zur Verf&uuml;gung zu stellen. Auch k&ouml;nnen diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenh&auml;ngen.</p>
            <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem h&auml;lt Google unter</p>
            <p><a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/partners</a></p>
            <p>weitere datenschutzrechtliche Informationen f&uuml;r Sie bereit, so bspw. auch zu den M&ouml;glichkeiten, die Datennutzung zu unterbinden.</p>
            <p>Zudem bietet Google unter</p>
            <p><a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
            <p>ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on l&auml;sst sich mit den g&auml;ngigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollm&ouml;glichkeit &uuml;ber die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics &uuml;bermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste &uuml;bermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie nat&uuml;rlich ebenfalls in dieser Datenschutzerkl&auml;rung.</p>
            <h4>Google-Maps</h4>
            <p>In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur &bdquo;Google&ldquo; genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild (&bdquo;EU-US Privacy Shield&ldquo;)</p>
            <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu erm&ouml;glichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
            <p>Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google &uuml;ber Ihren Internet-Browser ein Cookie auf Ihrem Endger&auml;t. Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei k&ouml;nnen wir nicht ausschlie&szlig;en, dass Google Server in den USA einsetzt.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalit&auml;t unseres Internetauftritts.</p>
            <p>Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu &uuml;bermitteln ist.</p>
            <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die M&ouml;glichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt &bdquo;Cookies&ldquo;.</p>
            <p>Zudem erfolgt die Nutzung von Google Maps sowie der &uuml;ber Google Maps erlangten Informationen nach den&nbsp;<a href="http://www.google.de/accounts/TOS" target="_blank" rel="noopener noreferrer">Google-Nutzungsbedingungen</a>&nbsp;und den&nbsp;<a href="http://www.google.com/intl/de_de/help/terms_maps.html" target="_blank" rel="noopener noreferrer">Gesch&auml;ftsbedingungen f&uuml;r Google Maps</a>.</p>
            <p>&Uuml;berdies bietet Google unter</p>
            <p><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">https://adssettings.google.com/authenticated</a></p>
            <p><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></p>
            <p>weitergehende Informationen an.</p>
            <p><a href="https://www.generator-datenschutzerklärung.de" target="_blank" rel="noopener">Muster-Datenschutzerklärung</a> der <a href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html" target="_blank" rel="noopener">Anwaltskanzlei Weiß &amp; Partner</a></p>
          </div>
        );
      }}
      />
  );
};

export default Datenschutz
